<script setup>
function getCurrentYear() {
  return new Date().getFullYear();
}
</script>

<template>
  <!--creer function somme-->
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-12">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6">
              <!-- Ajout du logo ici -->
              <div class="logo">
                <img
                  src="@/assets/img/logo/logocerist.png"
                  alt="Logo entreprise"
                />
              </div>
            </div>
            <!-- Ajout d'une sous-grille -->

            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="single_footer single_footer_address">
                <h4>Page Link</h4>
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="/about">About</a></li>
                  <li><a href="/contact">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--- END COL -->

        <div class="col-lg-4 col-sm-4 col-xs-12">
          <div class="single_footer">
            <h4>Services</h4>
            <ul v-for="service in services" :key="service.id">
              <li>
                <a :href="service.link">{{ service.name }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!--- END COL -->

        <div class="col-md-4 col-sm-4 col-xs-12">
          <div class="single_footer single_footer_address">
            <h4>Contact & Support</h4>
            <div class="signup_form">
              <li class="nav-item">
                <a class="nav-link" href="https://goo.gl/maps/Q4nxGME428MGcAcr9"
                  ><font-awesome-icon
                    icon="fa-brands fa-square-whatsapp"
                  />Targa Ouzemour, Abderahmane Mira University Bejaia,
                  Algeria</a
                >
              </li>
              <li class="nav-item">
                <span class="nav-link"
                  ><font-awesome-icon icon="fa-brands fa-square-whatsapp" />+213
                  657 904 123</span
                >
              </li>

              <li class="nav-item">
                <a class="nav-link" href="mailto:dsaru@cerist.dz"
                  ><font-awesome-icon
                    icon="fa-brands fa-square-whatsapp"
                  />dsaru@cerist.dz</a
                >
              </li>
            </div>
          </div>
          <div class="social_profile d-flex justify-content-center">
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/dsaru/"
                  ><font-awesome-icon icon="fa-brands fa-linkedin-in"
                /></a>
              </li>
              <li>
                <a href="https://twitter.com/DsaruBejaia"
                  ><font-awesome-icon icon="fa-brands fa-twitter"
                /></a>
              </li>
              <li>
                <a href="#"
                  ><font-awesome-icon icon="fa-brands fa-instagram"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCgVQuoQuBPHJyYCWqGKlycw"
                  ><font-awesome-icon icon="fa-brands fa-youtube"
                /></a>
              </li>
            </ul>
          </div>
        </div>
        <!--- END COL -->
      </div>
      <!--- END ROW -->
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <p class="copyright quick-links">
            Copyright © {{ getCurrentYear() }}
            <a href="https://datascience.cerist.dz/">DSARU</a>
          </p>
        </div>
        <!--- END COL -->
      </div>
      <!--- END ROW -->
    </div>
    <!--- END CONTAINER -->
  </div>

  <!-- Footer -->
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      services: [
        {
          id: 0,
          img: "medical/4D_MRI_2.png",
          name: "Medical & Pharma",
          desc: "",
          alt: "",
          link: "/services/medical",
        },
        {
          id: 1,
          img: "retail/intrusion-detection-computer-vision_1.webp",
          name: "Detection & Tracking",
          desc: "",
          alt: "",
          link: "/services/retail",
        },
        {
          id: 2,
          img: "industry/defect.jpeg",
          name: "Industry",
          desc: "",
          alt: "",
          link: "/services/industry",
        },
        {
          id: 3,
          img: "Recognition/image2.jpeg",
          name: "Recognition",
          desc: "",
          alt: "",
          link: "/services/recognition",
        },
      ],
    };
  },
};
</script>

<style>
h1,
h2,
h3,
h4,
h5,
h6 a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}
.footer {
  background-color: #1e4356;
  padding-top: 80px;
  padding-bottom: 40px;
}
/*END FOOTER SOCIAL DESIGN*/

@media only screen and (max-width: 768px) {
  .single_footer {
    margin-bottom: 30px;
  }
}
.single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}
.single_footer h4::after {
  display: block;
  height: 2px;
  width: 40px;
  background: #fff;
  margin-top: 20px;
}
.single_footer p {
  color: #fff;
}
.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single_footer ul li a {
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 15px;
  text-transform: capitalize;
}
.single_footer ul li a:hover {
  color: #6e99e6;
}

.single_footer_address ul li {
  color: #fff;
}
.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}
.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}
.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}
.subscribe__btn i {
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: #6e99e6;
}
button {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}
/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}
.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.social_profile ul li {
  float: left;
}
.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
.social_profile ul li a:hover {
  background: #6e99e6;
  border: 1px solid #6e99e6;
  color: #fff;
  border: 0px;
}
/*END SOCIAL PROFILE CSS*/
.copyright {
  margin-top: 70px;
  padding-top: 40px;
  color: #fff;
  font-size: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-align: center;
}
.copyright a {
  color: #01c7e9;
  transition: all 0.2s ease 0s;
}
.copyright a:hover {
  color: #01c7e9;
}
span.nav-link {
  color: #fff;
}
span.nav-link:hover {
  color: #6e99e6;
}
a.nav-link {
  color: #fff;
}
</style>
