<template>
  <div id="app">
    <NavComponent/>
    <router-view/>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
  
  import NavComponent from '@/components/NavComponent2.vue';
  import FooterComponent from './components/FooterComponent.vue';
  
  
  export default {
    name: 'App',
    components: {
    NavComponent,
    FooterComponent,
    
}
  }
  </script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000e6; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000e6; 
}
@import '@/assets/vendor/icofont/icofont.min.css';
@import '@/assets/css/style.css';
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendor/animate.css/animate.min.css";
@import "@/assets/vendor/icofont/icofont.min.css";
@import "@/assets/vendor/boxicons/css/boxicons.min.css";
@import "@/assets/vendor/venobox/venobox.css";
@import "@/assets/vendor/owl.carousel/assets/owl.carousel.min.css";
@import "@/assets/vendor/aos/aos.css";
@import "@/assets/css/style.css";

</style>
