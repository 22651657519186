import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { createPinia } from 'pinia'
import axios from 'axios'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import { faInstagram} from '@fortawesome/free-brands-svg-icons'
import { faYoutube} from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons'

/*import vue web camera*/
import VueWebCam from "vue-web-cam";
/* add icons to the library */
library.add(faLinkedinIn)
library.add(faTwitter)
library.add(faInstagram)
library.add(faYoutube)
library.add(faWhatsapp)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

 
import router from './router'
Vue.config.productionTip = false
const pinia = createPinia()

Vue.prototype.$axios = axios;
Vue.use(VueWebCam);
Vue.use(pinia)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component("LiveDetector", () => import('@/components/LiveDetector.vue'));
Vue.component("LiveDetector2", () => import('@/components/LiveDetectorCopy.vue'));
Vue.component("UploadFile", () => import('@/components/uploadFile.vue'));
Vue.component("WebcamShot", () => import('@/components/WebcamShot.vue'));
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
